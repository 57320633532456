export const getExistingUrlFulfillment = ({ meta_key_url, channel_type = null, id = null }) => {
  if (!meta_key_url) {
    return null
  }

  switch (meta_key_url) {
    case 'fulfillment':
      return '/fulfillment'

    case 'fulfillment-detail':
      return '/fulfillment/detail'

    case 'fulfillment-detail-update-order':
      return '/fulfillment/detail/update-order'

    case 'delivery-order':
      return '/delivery-order'

    case 'delivery-order-detail':
      return '/delivery-order/detail'

    case 'delivery-order-detail-update-order':
      return '/delivery-order/detail/update-order'

    case 'channel-fulfillment':
      return `/channel/${id}/${channel_type}/fulfillment`

    case 'channel-fulfillment-detail':
      return `/channel/${id}/${channel_type}/fulfillment/detail`

    case 'channel-fulfillment-detail-update-order':
      return `/channel/${id}/${channel_type}/detail/update-order`

    default:
      return null
  }
}

export const urlToDetailFulfillment = ({ meta_key_url, channel_type = null, id = null }) => {
  if (!meta_key_url) {
    return null
  }

  switch (meta_key_url) {
    case 'fulfillment':
    case 'fulfillment-detail-update-order':
      return '/fulfillment/detail'

    case 'delivery-order':
    case 'delivery-order-detail-update-order':
      return '/delivery-order/detail'

    case 'channel-fulfillment':
    case 'channel-fulfillment-detail-update-order':
    case 'channel-fulfillment-stock-inventory':
      return `/channel/${id}/${channel_type}/fulfillment/detail`

    default:
      return null
  }
}

export const getUrlAfterDetailFulfillment = ({ meta_key_url, channel_type = null, id = null }) => {
  if (!meta_key_url) {
    return null
  }

  if (meta_key_url === 'fulfillment-detail') {
    return '/fulfillment'
  }

  if (meta_key_url === 'delivery-order-detail') {
    return '/delivery-order'
  }

  if (meta_key_url === 'channel-fulfillment-detail') {
    return `/channel/${id}/${channel_type}/fulfillment`
  }

  return null
}

export const getUrlToUpdateOrderFromFulfillmentDetailPage = ({ meta_key_url, channel_type = null, id = null }) => {
  if (!meta_key_url) {
    return null
  }

  switch (meta_key_url) {
    case 'fulfillment-detail':
      return '/fulfillment/detail/update-order'

    case 'delivery-order-detail':
      return '/delivery-order/detail/update-order'

    case 'channel-fulfillment-detail':
      return `/channel/${id}/${channel_type}/fulfillment/detail/update-order`

    default:
      return null
  }
}

export const setBackgroundColorStatus = (param) => {
  if (!param) {
    return null
  }

  const value = param.toLowerCase()

  if (['created', 'selesai'].find(item => item === value)) {
    return '#0A89FF'
  }

  if (['split_shipment', 'split_fulfillment'].find(item => item === value)) {
    return '#454655'
  }

  if (['confirmed', 'accepted'].find(item => item === value)) {
    return '#00BBD9'
  }

  if (['pick_confirmed', 'packing_completed'].find(item => item === value)) {
    return '#999999'
  }

  if (['good_issued', 'shipped'].find(item => item === value)) {
    return '#2D9500'
  }

  if (['delivered', 'buyer_accepted'].find(item => item === value)) {
    return '#4d4d4d'
  }

  if (['canceled', 'cancel_requested', 'gagal'].find(item => item === value)) {
    return '#E00000'
  }

  return '#EC4426'
}

export const setWordingBookingState = (param) => {
  if (!param) {
    return '-'
  }

  const value = param.toLowerCase()

  switch (value) {
    case 'not_yet_allocated':
      return 'Unallocated'

    case 'success_allocated':
      return 'Allocated'
    
    case 'allocation_failed':
      return 'OOS / Unconfirm Product'

    default:
      // eslint-disable-next-line no-case-declarations
      const text = value.replace('_', ' ')
      return text[0].toUpperCase() + text.slice(1).toLowerCase()
  }
}

export const setWordingFulfillmentState = (param, state) => {
  if (!param) {
    return null
  }

  const value = param.toLowerCase()

  if (['created'].find(item => item === value)) {
    return 'Baru'
  }

  if (['split_shipment', 'split_fulfillment'].find(item => item === value)) {
    return 'Pengiriman Terpisah'
  }

  if (['confirmed', 'accepted'].find(item => item === value)) {
    return 'Lakukan Proses'
  }

  if (['pick_confirmed'].find(item => item === value)) {
    return 'Pengambilan'
  }

  if (['good_issued'].find(item => item === value)) {
    return 'Siap Kirim'
    // return state?.toLowerCase() === 'delivered'
    //   ? 'Terkirim'
    //   : state?.toLowerCase() === 'created'
    //   ? 'Siap Dikirim'
    //   : 'Pengiriman'
  }

  if (['packing_completed'].find(item => item === value)) {
    return 'Pengemasan'
  }

  if (['shipped'].find(item => item === value)) {
    return 'Dalam Pengiriman'
  }

  if (['delivered', 'buyer_accepted'].find(item => item === value)) {
    return 'Terkirim'
  }

  if (['canceled'].find(item => item === value)) {
    return 'Batal'
  }

  if (['cancel_requested'].find(item => item === value)) {
    return 'Request Pembatalan'
  }

  return null
}
